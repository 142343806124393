<template>
    <v-container fluid>
        <v-form @submit.prevent="getIntegrationErrors(search)">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat light>
                    <v-card-text class="pa-0">
                        <v-row>
                            <v-col cols="12">
                                <v-subheader class="headline">{{ $t('menu_integration_errors') }}</v-subheader>
                            </v-col>

                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-0 py-5">
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-select :value="perPage" :items="perPageItems" :disabled="loading"
                                          :label="$t('items_per_page')" prepend-icon="mdi-counter"
                                          @input="perPage = options.itemsPerPage = Number($event)"
                                          hide-details dense></v-select>
                            </v-col>
                            <v-col cols="12" sm="8" class="text-right">
                                <v-btn type="submit" :disabled="invalid || loading" :block="$vuetify.breakpoint.xsOnly"
                                       color="primary">{{ $t('search') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>

                </v-card>
            </ValidationObserver>
        </v-form>
        <v-card flat light>
            <v-card-text class="px-0">
                <v-data-table :headers="headers" :items="integration_errorItems" :options.sync="options" :page.sync="page"
                              :items-per-page="perPage" :server-items-length="totalIntegrationErrors" :sort-by.sync="sortBy"
                              :sort-desc.sync="sortDir" :loading="loading" :locale="lang"
                              :loading-text="$t('loading_please_wait')" class="elevation-1"
                              hide-default-footer>
                    <template v-slot:item.created_at="{ item }">
                        {{  item.created_at ? $moment(item.created_at).format('YYYY-MM-DD HH:mm') : ''}}
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" class="mr-2" @click="showIntegrationError(item)" :title="$t('details')">
                                    mdi-menu
                                </v-icon>
                            </template>
                            <span>{{$t('details')}}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click="deleteIntegrationError(item)" :title="$t('delete')">
                                    mdi-delete-outline
                                </v-icon>
                            </template>
                            <span>{{$t('delete')}}</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-pagination v-show="totalIntegrationErrors > perPage" v-model="page" :total-visible="totalVisiblePag"
                              :length="pageCount" :disabled="loading"></v-pagination>
            </v-card-actions>
        </v-card>
        <v-dialog v-if="dialogDetails" v-model="dialogDetails" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-toolbar-title>{{ $t('details') }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon dark @click="cancelDetails()">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <v-card-text >
                    <v-row>
                        <v-col cols="12">
                            <br/>
                            <b> Название интеграции:</b> {{ detailsIntegration.name ? detailsIntegration.name :  $t('no_data')  }} <br/> <br/>
                            <b> Ошибка при интеграции:</b> {{ detailsIntegration.error ? detailsIntegration.error :  $t('no_data')  }}
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>

    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {mapGetters} from "vuex"

    export default {
        name: "IntegrationErrors",
        components: {
            ValidationObserver
        },
        data() {
            return {

                sortBy: "id",
                sortDir: false,
                loading: false,
                dialogDetails: false,
                detailsIntegration: [],
                options: {},
                page: 1,
                pageCount: 0,
                perPage: 5,
                totalVisiblePag: 5,
                totalIntegrationErrors: 0,
                integration_errorItems: [],
                headers: [
                    {
                        text: this.$t('name'),
                        align: "left",
                        sortable: true,
                        value: "name"
                    },
                    {
                        text: this.$t('integration_error'),
                        align: "left",
                        sortable: true,
                        value: "error"
                    },
                    {
                        text: this.$t('integration_date'),
                        align: "left",
                        sortable: true,
                        value: "created_at",
                        width: 150,
                    },
                    {
                        text: this.$t('actions'),
                        align: "center",
                        value: 'action',
                        sortable: false,
                        width: 120,
                    },
                ],
            }
        },
        computed: {
            ...mapGetters(['lang', 'itemsPerPage', 'perPageItems']),
        },
        mounted() {
            this.options.itemsPerPage = this.perPage = this.itemsPerPage
        },
        watch: {
            options: {
                handler() {
                    this.getIntegrationErrors()
                },
                deep: false
            }
        },
        methods: {
            showIntegrationError(item) {
                this.detailsIntegration = item
                this.dialogDetails = true
            },
            async getIntegrationErrors(type) {
                var _this = this
                this.progress = 0
                this.loading = true
                const {
                    sortBy,
                    sortDesc,
                    page,
                    itemsPerPage
                } = this.options
                let params = {}
                if (sortBy[0] !== undefined) {
                    params.sortBy = sortBy[0]
                }
                if (sortDesc[0] !== undefined) {
                    params.sortDir = sortDesc[0] ? 'asc' : 'desc'
                }
                if (page !== undefined) {
                    params.page = page
                }
                if (type === 'search') {
                    params.page = 1
                }
                if (itemsPerPage !== undefined) {
                    params.perPage = itemsPerPage
                }





                await this.$http
                    .get("admin/integration_error", {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.integration_errorItems = res.body.data
                        this.page = res.body.meta.current_page
                        this.totalIntegrationErrors = res.body.meta.total
                        this.pageCount = res.body.meta.last_page
                    })
                    .catch(err => {
                        this.$toastr.success(this.$t('failed_to_get_list_integration_errors'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async deleteIntegrationError(item) {
                if (confirm(this.$t('delete_integration_error'))) {
                    var _this = this
                    this.loading = true
                    await this.$http
                        .delete(`admin/integration_error/${item.id}`)
                        .then(res => {
                            this.$toastr.success(this.$t('integration_error_has_been_deleted'))
                            this.getIntegrationErrors()
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('integration_error_has_not_been_deleted'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.loading = false
                        })
                }
            },
            cancelDetails(){
                this.dialogDetails = false
                this.detailsIntegration = []
            },
        }
    }
</script>
